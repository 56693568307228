@font-face {
   font-family: 'SF Pro Text';
   src: local('SF Pro Text'), url('../fonts/SFProText-BoldItalic.eot');
   src: local('SF Pro Text'), url('../fonts/SFProText-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/SFProText-BoldItalic.woff2') format('woff2'), url('../fonts/SFProText-BoldItalic.woff') format('woff'), url('../fonts/SFProText-BoldItalic.ttf') format('truetype'), url('../fonts/SFProText-BoldItalic.svg#SFProText-BoldItalic') format('svg');
   font-weight: bold;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'SF Pro Text';
   src: local('SF Pro Text'), url('../fonts/SFProText-Bold.eot');
   src: local('SF Pro Text'), url('../fonts/SFProText-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/SFProText-Bold.woff2') format('woff2'), url('../fonts/SFProText-Bold.woff') format('woff'), url('../fonts/SFProText-Bold.ttf') format('truetype'), url('../fonts/SFProText-Bold.svg#SFProText-Bold') format('svg');
   font-weight: bold;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'SF Pro Text';
   src: local('SF Pro Text'), url('../fonts/SFProText-Heavy.eot');
   src: local('SF Pro Text'), url('../fonts/SFProText-Heavy.eot?#iefix') format('embedded-opentype'), url('../fonts/SFProText-Heavy.woff2') format('woff2'), url('../fonts/SFProText-Heavy.woff') format('woff'), url('../fonts/SFProText-Heavy.ttf') format('truetype'), url('../fonts/SFProText-Heavy.svg#SFProText-Heavy') format('svg');
   font-weight: 900;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'SF Pro Text';
   src: local('SF Pro Text'), url('../fonts/SFProText-HeavyItalic.eot');
   src: local('SF Pro Text'), url('../fonts/SFProText-HeavyItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/SFProText-HeavyItalic.woff2') format('woff2'), url('../fonts/SFProText-HeavyItalic.woff') format('woff'), url('../fonts/SFProText-HeavyItalic.ttf') format('truetype'), url('../fonts/SFProText-HeavyItalic.svg#SFProText-HeavyItalic') format('svg');
   font-weight: 900;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'SF Pro Text';
   src: local('SF Pro Text'), url('../fonts/SFProText-Light.eot');
   src: local('SF Pro Text'), url('../fonts/SFProText-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/SFProText-Light.woff2') format('woff2'), url('../fonts/SFProText-Light.woff') format('woff'), url('../fonts/SFProText-Light.ttf') format('truetype'), url('../fonts/SFProText-Light.svg#SFProText-Light') format('svg');
   font-weight: 200;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'SF Pro Text';
   src: local('SF Pro Text'), url('../fonts/SFProText-LightItalic.eot');
   src: local('SF Pro Text'), url('../fonts/SFProText-LightItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/SFProText-LightItalic.woff2') format('woff2'), url('../fonts/SFProText-LightItalic.woff') format('woff'), url('../fonts/SFProText-LightItalic.ttf') format('truetype'), url('../fonts/SFProText-LightItalic.svg#SFProText-LightItalic') format('svg');
   font-weight: 200;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'SF Pro Text';
   src: local('SF Pro Text'), url('../fonts/SFProText-Medium.eot');
   src: local('SF Pro Text'), url('../fonts/SFProText-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/SFProText-Medium.woff2') format('woff2'), url('../fonts/SFProText-Medium.woff') format('woff'), url('../fonts/SFProText-Medium.ttf') format('truetype'), url('../fonts/SFProText-Medium.svg#SFProText-Medium') format('svg');
   font-weight: 500;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'SF Pro Text';
   src: local('SF Pro Text'), url('../fonts/SFProText-MediumItalic.eot');
   src: local('SF Pro Text'), url('../fonts/SFProText-MediumItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/SFProText-MediumItalic.woff2') format('woff2'), url('../fonts/SFProText-MediumItalic.woff') format('woff'), url('../fonts/SFProText-MediumItalic.ttf') format('truetype'), url('../fonts/SFProText-MediumItalic.svg#SFProText-MediumItalic') format('svg');
   font-weight: 500;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'SF Pro Text';
   src: local('SF Pro Text'), url('../fonts/SFProText-Semibold.eot');
   src: local('SF Pro Text'), url('../fonts/SFProText-Semibold.eot?#iefix') format('embedded-opentype'), url('../fonts/SFProText-Semibold.woff2') format('woff2'), url('../fonts/SFProText-Semibold.woff') format('woff'), url('../fonts/SFProText-Semibold.ttf') format('truetype'), url('../fonts/SFProText-Semibold.svg#SFProText-Semibold') format('svg');
   font-weight: 600;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'SF Pro Text';
   src: local('SF Pro Text'), url('../fonts/SFProText-RegularItalic.eot');
   src: local('SF Pro Text'), url('../fonts/SFProText-RegularItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/SFProText-RegularItalic.woff2') format('woff2'), url('../fonts/SFProText-RegularItalic.woff') format('woff'), url('../fonts/SFProText-RegularItalic.ttf') format('truetype'), url('../fonts/SFProText-RegularItalic.svg#SFProText-RegularItalic') format('svg');
   font-weight: normal;
   font-style: italic;
   font-display: swap;
}

@font-face {
   font-family: 'SF Pro Text';
   src: local('SF Pro Text'), url('../fonts/SFProText-Regular.eot');
   src: local('SF Pro Text'), url('../fonts/SFProText-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/SFProText-Regular.woff2') format('woff2'), url('../fonts/SFProText-Regular.woff') format('woff'), url('../fonts/SFProText-Regular.ttf') format('truetype'), url('../fonts/SFProText-Regular.svg#SFProText-Regular') format('svg');
   font-weight: normal;
   font-style: normal;
   font-display: swap;
}

@font-face {
   font-family: 'SF Pro Text';
   src: local('SF Pro Text'), url('../fonts/SFProText-SemiboldItalic.eot');
   src: local('SF Pro Text'), url('../fonts/SFProText-SemiboldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/SFProText-SemiboldItalic.woff2') format('woff2'), url('../fonts/SFProText-SemiboldItalic.woff') format('woff'), url('../fonts/SFProText-SemiboldItalic.ttf') format('truetype'), url('../fonts/SFProText-SemiboldItalic.svg#SFProText-SemiboldItalic') format('svg');
   font-weight: 600;
   font-style: italic;
   font-display: swap;
}

/* FONT STYLE 
@font-face {
    font-family: 'SF Pro Text';
    font-weight: 800;
    src: url('../fonts/SF-Pro-Text-Heavy.otf') format('otf');
    font-display: swap;
 }
 
 @font-face {
    font-family: 'SF Pro Text';
    font-weight: 700;
    src: url('../fonts/SF-Pro-Text-Bold.otf') format('otf');
    font-display: swap;
 }
 
 @font-face {
    font-family: 'SF Pro Text';
    font-weight: 600;
    src: url('../fonts/SF-Pro-Text-Semibold.otf') format('otf');
    font-display: swap;
 }
 
 @font-face {
    font-family: 'SF Pro Text';
    font-weight: 500;
    src: url('../fonts/SF-Pro-Text-Medium.otf') format('otf');
    font-display: swap;
 }
 
 @font-face {
    font-family: 'SF Pro Text';
   font-weight: 400;
   src: url('../fonts/SF-Pro-Text-Regular.otf') format('otf');
   font-display: swap;
 }
 
 @font-face {
    font-family: 'SF Pro Text';
   font-weight: 300;
   src: url('../fonts/SF-Pro-Text-Light.otf') format('otf');
   font-display: swap;
 }*/

/* Common */

/* width */

/* ::-webkit-scrollbar {
   width: 7px;
}
/* Track *
::-webkit-scrollbar-track {
   box-shadow: inset 0 0 1px 0px grey;
   border-radius: 0px;
}
/* Handle *
::-webkit-scrollbar-thumb {
   background: rgb(161, 161, 161);
   border-radius: 10px;
}
/* Handle on hover *
::-webkit-scrollbar-thumb:hover {
   background: rgb(109, 109, 109);
} */

html {
   margin: 0;
   padding: 0;
   scrollbar-color: #FFF;
}

body {
   background: #FFF;
   color: #000;
   font-family: 'SF Pro Text', sans-serif !important;
   font-weight: 400;
}

ul {
   padding: 0;
   margin: 0;
}

li {
   list-style: none;
}

h1, h2, h3, h4, h5, h6, button, input, optgroup, select, textarea {
   font-family: 'SF Pro Text', sans-serif;
}

a, a:hover, a:focus {
   outline: none;
   text-decoration: none;
}

.container {
   max-width: 1220px;
   padding: 0 10px;
   width: 100%;
}

.fixed {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   z-index: 999;
   background: #000;
}

.w-100 {
   width: 100%;
}

.btn {
   height: 55px;
   width: 237px;
   border-radius: 5px;
   font-family: 'SF Pro Text', sans-serif;
   font-size: 22px;
   font-weight: 600;
   line-height: 36px;
   background-color: transparent;
   color: #000;
   border: 1px solid #fff;
}

.btn:hover, .btn:focus {
   color: #fff;
}

.divider {
   width: 100%;
   border: .5px solid #191919;
   float: left;
}

.w-divider {
   width: 100%;
   border: .5px solid rgba(255, 255, 255, 0.8);
   float: left;
}

.mobile-only {
   display: none !important;
}

button:focus, input:focus {
   outline: 0;
   box-shadow: unset;
}

.btn_cusDrop:focus {
   outline: 0;
   box-shadow: unset !important;
}

/* Momin */

.service-demoslider .react-multiple-carousel__arrow {
   background: #000;
}

/* .react-multi-carousel-list{
   display: unset;
} */

.react-multiple-carousel__arrow--left {
   left: calc(0.5% + 1px);
}

.react-multiple-carousel__arrow--right {
   right: calc(0.5% + 1px);
}

.service-demoslider .react-multiple-carousel__arrow--left::before, .service-demoslider .react-multiple-carousel__arrow--right::before {
   color: #ffffff;
   font-size: 16px;
}

.service-demoslider .react-multiple-carousel__arrow--left:hover, .service-demoslider .react-multiple-carousel__arrow--right:hover {
   background: #000;
   box-shadow: 0 12px 30px rgba(0, 0, 0, .10);
}

.service-demoslider .react-multiple-carousel__arrow--left, .service-demoslider .react-multiple-carousel__arrow--right {
   min-width: 45px;
   min-height: 45px;
   border-radius: 50%;
   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
   z-index: 15;
   background: #FFF;
}

.service-demoslider .react-multiple-carousel__arrow--left::before, .service-demoslider .react-multiple-carousel__arrow--right::before {
   color: #000;
   font-weight: 900;
}

.service-demoslider .react-multiple-carousel__arrow--left:hover, .service-demoslider .react-multiple-carousel__arrow--right:hover {
   background: #FFF;
}

.react-multiple-carousel__arrow--left {
   left: calc(0.5% + 1px) !important;
}

.react-multiple-carousel__arrow--right {
   right: calc(0.5% + 1px) !important;
}

.w_Controler .react-multiple-carousel__arrow {
   min-width: 30px;
   min-height: 30px;
   border-radius: 3px;
   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
   z-index: 15;
   background: #FFF;
   /* box-shadow: 0 12px 50px rgba(0,0,0,.10); */
}

.w_Controler .react-multiple-carousel__arrow::before {
   color: #000;
}

.b_Controler .react-multiple-carousel__arrow, .b_Controler .react-multiple-carousel__arrow:hover {
   /* background: #000; */
   background: #FFF;
}

.b_Controler .react-multiple-carousel__arrow::before {
   color: #000;
   /* color: #FFF; */
}

.w_Controler .react-multiple-carousel__arrow:hover {
   background: #FFF;
}

.smallLoader-wrap {
   width: 100%;
   display: block;
   text-align: center;
}

.small-loader {
   width: 25px;
   height: 25px;
}

.error_text {
   font-size: 12px;
   color: #d63e4b;
   font-family: 'SF Pro Text';
   line-height: normal;
   font-weight: 500;
   margin-top: 10px;
   text-align: left;
}

.error_text.pos_tA {
   position: absolute;
   top: 90%;
}

.error_border {
   border-bottom: 2px solid #d63e4b !important;
}

.error_borderFull {
   border-color: #d63e4b !important;
}

.api_errorText {
   font-size: 15px;
   margin: 10px 0;
   width: 100%;
   display: inline-block;
   position: relative;
   font-weight: 500;
   line-height: normal;
   color: #d63e4b;
}

.modal-backdrop.show {
   opacity: 0.8;
}

button:disabled, input:disabled {
   cursor: no-drop;
}

.contact_modal .modal-md {
   width: 100%;
   max-width: 700px;
}

.contact_modal .modal-content {
   border-radius: 10px;
}

.contact_modal .modal-body {
   padding: 0;
}

.contact_modal .modal_body {
   min-height: 356px;
   display: table;
   width: 100%;
}

.contact_modal .block_succeM {
   display: table-cell;
   vertical-align: middle;
   width: 100%;
   text-align: center;
   padding: 0 35px;
}

.contact_modal .block_succeM p {
   font-family: 'SF Pro Text';
   color: #12245a;
   font-size: 28px;
   letter-spacing: 0;
   line-height: 34px;
   text-align: center;
   margin: 0 auto 15px;
   font-weight: 700;
}

.contact_modal .block_succeM button {
   background: transparent;
   border: 0;
   color: #d63e4b;
   font-size: 15px;
   font-weight: 600;
   line-height: normal;
   display: block;
   margin: 60px auto 0;
}

.rating svg {
   vertical-align: baseline;
}

.mar_b8 {
   margin-bottom: 8px !important;
}

.mar_b30 {
   margin-bottom: 30px;
}

.marT-150 {
   margin-top: -180px !important;
}

.btn_post {
   width: 100%;
   max-width: 200px;
   font-size: 18px;
   font-family: 'SF Pro Text';
   border: 0;
   background: #000;
   color: #FFF;
   padding: 2px 10px;
   text-align: center;
   font-weight: 600;
   line-height: 36px;
   margin-top: 24px;
   border-radius: 7px;
   position: relative;
}
.btn_post-div {
   width: 100%;
}

.btn_post .arrow-right {
   width: 20px;
   height: 34px;
   right: 13px;
   top: 1px;
   transform: scale(.47);
   background: url('../images/sprite-medium-home.svg') no-repeat 0 0;
   display: inline-block;
   position: absolute;
   background-position: 0 -558px;
}

.custom_skillList ul li {
   width: 33.33% !important;
   float: left;
   padding-right: 30px;
}
.custom_skillList ul li:nth-child(3n+3){
   padding-right: 0;
}

.text-green {
   font-family: 'SF Pro Text';
   font-size: 13px;
   color: #1a8c1d;
   line-height: 18px;
   text-align: center;
   font-weight: 600;
}

/* .react-multi-carousel-track{
   width: 100%!important;
}
.react-multi-carousel-item{
   flex: 0 1 auto !important;
} */

/* momin over */

.errorBorder {
   border: 1px solid #ff5555 !important;
}

.errorText {
   color: #ff5555;
   margin-bottom: 0;
   position: absolute;
   bottom: 0;
   font-size: 14px;
}

/* Home page*/

.main-content {
   margin-top: 96px;
   width: 100%;
   float: left;
}

.main-content.to_0 {
   margin-top: 55px
}

.marT_55 {
   margin-top: 55px;
}

.yellow-btn {
   background-color: #ffd500;
   color: #000;
   border: 1px solid transparent;
}

.yellow-btn:hover, .yellow-btn:focus {
   color: #000;
}

.white-bg {
   background: #fff;
   padding: 70px 0 40px;
   position: relative;
   float: left;
   width: 100%;
}

.caption {
   color: #000;
   font-size: 34px;
   line-height: 48px;
   font-weight: 700;
   float: left;
}

.white-bg .link {
   float: right;
   height: 40px;
   width: 200px;
   border: 1.5px solid #000;
   border-radius: 60px;
   color: #000;
   font-family: 'SF Pro Text', sans-serif;
   font-size: 18px;
   font-weight: 600;
   line-height: 36px;
   text-align: center;
   position: relative;
}

.white-bg .link .arrow-right {
   background: url('../../assets/images/sprite-medium-home.svg') no-repeat 0 0;
   display: inline-block;
   transform: scale(0.5);
   width: 15px;
   height: 26px;
   position: absolute;
   right: 20px;
   top: 5px;
}

.mar_t55 {
   margin-top: 55px;
}

/* skills */

.skills {
   background: #fbfbfb;
   padding: 10px 0;
   width: 100%;
   float: left;
}

.skill-list {
   padding: 30px 0;
   width: 100%;
   float: left;
   text-align: center;
}

.skill-list ul {
   width: 100%;
   display: inline-block;
}

/* column-count: 3; */

.skill-list h2 {
   color: #000;
   font-family: 'SF Pro Text', sans-serif;
   font-size: 18px;
   font-weight: 700;
   line-height: 21px;
   margin: 0 0 35px;
   text-align: left;
}

.skill-list li {
   text-align: left;
   width: 33.33%;
   float: left;
}

.skill-list li a {
   color: #444;
   display: block;
   font-size: 15px;
   line-height: 28px;
   transition: 0.3s ease-in-out;
   max-width: fit-content;
   /* max-width: 100%; */
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: nowrap;
   /* padding-right: 30px; */
}

.skill-list li a:hover {
   text-decoration: underline;
   color: #000;
   font-weight: 600;
}

.skills .browse {
   margin: 40px auto 20px;
   height: 41px;
   width: 200px;
   border: 1px solid #000;
   border-radius: 60px;
   line-height: 38px;
   display: inline-block;
   color: #000;
   text-align: center;
}

.skill-list .view_more {
   /* display: none;  */
   color: #444;
   float: left;
   margin-top: 8px;
   text-decoration: underline;
   font-size: 13px;
   background: transparent;
   border: none;
   outline: none;
   display: block;
   padding: 0;
   text-align: left;
   width: 100%;
   font-weight: 700;
}

.load_wrap {
   width: 100%;
   display: flex;
   min-height: 520px;
   align-items: center;
   justify-content: center;
}

.main-menu li .badge.new-badge{
   background: #ffd500;
   color: #000000;
   position: absolute;
   top: 4px;
   right: -1px;
   font-size: 65%;
}
.new-badge-more{
   background: #ffd500;
    color: #000000;
    font-size: 85%;
    margin-left: 5px;
    border-radius: 4px;
    padding: 1px 5px;
}

/* Responsive */

@media (max-width: 1145px) {
   .skill-list li a {
      font-size: 13px;
   }
   .skills {
      padding: 70px 0;
   }
}

@media (max-width: 991px) {
   .skills {
      padding: 40px 0;
   }
   .skill-list h2 {
      font-size: 17px;
      line-height: 20px;
      margin: 0 0 25px;
   }
   .skill-list li {
      width: 50%;
   }
   .skill-list li a {
      line-height: 26px;      
   }
   
}

@media (max-width: 767px) {
   .skills {
      padding: 30px 0;
   }
   .skill-list {
      padding: 25px 0;
   }
   .skill-list ul {
      column-count: 3;
   }
   .skill-list li a {
      font-size: 12.5px;
      line-height: 24px;
      /* padding-right: 30px; */
   }
   .skills .browse {
      margin: 20px auto 20px;
      height: 40px;
      width: 180px;
   }
}

@media (max-width: 576px) {
   .skills, .skill-list {
      padding: 20px 0;
   }
   .skill-list.height_auto ul {
      max-height: initial;
   }
   .skill-list h2 {
      margin-bottom: 15px;
   }
   .skill-list ul {
      column-count: 1;
   }
   .skill-list li {
      width: 100%;
   }
   .skill-list li a {
      font-size: 14px;
      padding-left: 0px;
   }
   .skill-list ul {
      max-height: 200px;
      overflow: hidden;
   }
   .skill-list.expanded ul {
      max-height: initial;
   }
   .skill-list .view_more {
      color: #444;
      float: left;
      margin-top: 8px;
      text-decoration: underline;
      font-size: 13px;
      background: transparent;
      border: none;
      outline: none;
      display: block;
      padding: 0;
      text-align: left;
      width: 100%;
      font-weight: 700;
   }
}

/* skills over  */

/* Responsive */

@media (max-width: 1145px) {
   .container {
      max-width: 100%;
      padding: 0 20px;
   }
   body {
      font-size: 0.9rem;
   }
   .main-content {
      margin-top: 94px;
   }
   .marT_55 {
      margin-top: 53px;
   }
   .btn {
      height: 52px;
      width: 230px;
      font-size: 20px;
   }
   .banner .yellow-btn {
      margin-right: 30px;
   }
   .right-menu li a, nav ul a {
      font-size: 13px;
   }
   .white-bg {
      padding: 60px 0 20px;
   }
   .caption {
      font-size: 32px;
      line-height: 44px;
   }
   .owl-carousel {
      padding-top: 1px;
      max-width: 90vw;
      margin: 0 auto;
      float: none;
   }
   .marT-150 {
      margin-top: -110px !important;
   }
}

@media (max-width: 991px) {
   .container {
      padding: 0 15px;
   }
   .btn {
      height: 50px;
      width: 180px;
      font-size: 18px;
   }
   .caption {
      font-size: 28px;
      line-height: 38px;
   }
   .main-content {
      margin-top: 55px;
   }
   .marT_55 {
      margin-top: 55px;
   }
   .owl-carousel {
      max-width: 80vw;
   }
   .react-multiple-carousel__arrow--left {
      left: calc(0% + 1px) !important
   }
   .react-multiple-carousel__arrow--right {
      right: calc(0% + 1px) !important
   }
   /* .w_Controler .react-multiple-carousel__arrow{
         min-width:20px;
         min-height: 20px;
      } */
   .custom_skillList ul li {
      width: 50% !important;
      float: left;
   }
   .marT-150 {
      margin-top: -70px !important;
   }
   .custom_skillList ul li:nth-child(3n+3){
      padding-right: 30px;
   }
   .custom_skillList ul li:nth-child(2n+2) {
      padding-right: 0;
   }
}

@media (max-width: 767px) {
   .container {
      padding: 0 15px;
   }
   .btn {
      height: 42px;
      width: 135px;
      font-size: 16px;
      line-height: 29px;
      padding: 6px;
   }
   .mobile-only {
      display: block !important;
   }
   .errorText {
      font-size: 13px;
   }
   .caption {
      font-size: 22px;
      line-height: 32px;
   }
   .white-bg {
      padding-top: 25px;
   }
   .react-multiple-carousel__arrow {
      min-width: 15px;
      min-height: 15px;
   }
   .react-multiple-carousel__arrow--left::before, .react-multiple-carousel__arrow--right::before {
      font-size: 15px !important;
   }
   .w_Controler .react-multiple-carousel__arrow {
      min-width: 15px;
      min-height: 15px;
   }
   .btn_post {
      max-width: 140px;
      font-size: 16px;
      text-align: left;
      padding: 0 18px;
   }
   .btn_post-div {
      text-align: center;
   }
   .custom_skillList ul li {
      width: 100% !important;
      float: left;
   }
   .marT-150 {
      margin-top: 0px !important;
   }
}

@media (max-width: 576px) {
   .custom_skillList ul {
      max-height: initial !important;
   }
   .custom_skillList ul li {
      width: 100% !important;
      float: left;
      padding-right: 15px;
   }
   .custom_skillList ul li:nth-child(3n+3){
      padding-right: 15px;
   }
   .custom_skillList ul li:nth-child(2n+2) {
      padding-right: 15px;
   }
}

@media (max-width: 350px) {}