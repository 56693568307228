.main_loader{
    width: 100%;
    height: 100vh;
    display: table;
}
.loader_wrap{
    width: 100%;
    height: 20px;
    background:#FFF;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}
.loader_wrap h2{
    font-size: 5vw;
    color: #FFF;
    font-weight: 800;
    font-family: 'SF Pro Text';
    line-height: 100px;
    -webkit-text-stroke: 0.1vw #383d52;
    -moz-text-stroke:0.1vw #383d52;
    -o-text-stroke:0.1vw #383d52;
    -ms-text-stroke:0.1vw #383d52;
    position: relative;
    display: inline-block;
}
.loader_wrap h2::before{
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    color: #000;
    /* background: linear-gradient(0, #000, yellow); */
    -webkit-text-stroke:0vw #383d52;
    -moz-text-stroke:0vw #383d52;
    -o-text-stroke:0vw #383d52;
    -ms-text-stroke:0vw #383d52;
    /* border-right: 4px solid #000; */
    overflow: hidden;
    animation: animate 2.5s linear infinite;
}
@keyframes animate{
    0%,10%, 100%{
        width: 0%;
    }
    70%,90%{
        width: 100%;
    }
}
/* .logo_atext{
    width: 300px;
}
.logo_atext #_24Task{
    fill:#FFF;
    stroke:blue;
} */